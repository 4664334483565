import React, {Component} from 'react';
import {GameController} from "phosphor-react";
import {Link} from "react-router-dom";

class Navbar extends Component<any, any> {
    render() {
        return (
            <div className="navbar bg-neutral">
                <div className="flex-1">
                    <Link to="/" className="btn btn-ghost normal-case text-xl">GamesBoxD</Link>
                </div>
                <div className="flex-none">
                    <div className="dropdown dropdown-end">
                        <label className="btn btn-ghost">
                            <GameController tabIndex={0} size={32} weight="fill" />
                        </label>
                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                            <li>See all games</li>
                            <li>My games</li>
                            <li>Wishlist</li>
                        </ul>
                    </div>
                    <div className="dropdown dropdown-end">
                        <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                            <div className="w-10 rounded-full">
                                <img src="https://placeimg.com/80/80/people" alt={"Profile"} />
                            </div>
                        </label>
                        <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                            <li>Profile</li>
                            <li>Settings</li>
                            <li>Logout</li>
                            <li><Link to="/login">Login</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Navbar;
