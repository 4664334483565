import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import Navbar from './components/navbar/Navbar';
import Home from "./home/home";
import Login from "./onboarding/login/login";
import Register from "./onboarding/register/register";
import Profile from "./profile/profile";

function App() {
  return (
      <div>
          <Router>
              <Navbar />
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/profile" element={<Profile />} />
              </Routes>
          </Router>
      </div>
  );
}

export default App;
