import React, {Component} from "react";
import {Link} from "react-router-dom";

class Login extends Component<any, any> {
    render() {
        return (
            <div className="flex justify-center items-center w-full h-[500px]">
                <div className="card w-96 bg-neutral shadow-xl">
                    <div className="card-body">
                        <h2 className="card-title">Login</h2>
                        <div>
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="label-text">Username</span>
                                </label>
                                <input type="text" placeholder="Type here"
                                       className="input input-bordered input-primary w-full max-w-xs"/>
                                <br/>
                                <label className="label">
                                    <span className="label-text">Password</span>
                                </label>
                                <input type="password" placeholder="Type here"
                                       className="input input-bordered input-primary w-full max-w-xs"/>
                                <br/>
                            </div>
                        </div>
                        <div className="card-actions justify-center my-4">
                            <button className="btn btn-primary w-full">Login</button>
                        </div>
                        <p className="card-actions justify-end text-xs">
                            Don't have an account yet? <Link className="link link-secondary" to="/register">
                            Create one now.</Link>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
