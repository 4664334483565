import React from "react";
import PlatformComponent from "../platformComponent";

export default GameComponent;

function GameComponent(props: any) {

    const game = props.game;

    return (
        <div className="card lg:w-[280px] md:w-[250px] w-full bg-neutral shadow-xl m-5">
            <figure><img src={game.cover} alt={game.name}/></figure>
            <div className="card-body">
                <h2 className="card-title">{game.name}</h2>
                <div className={"flex flex-wrap"}>
                    {game.platforms.filter((g: any) => !g.includes('PC')).map((platform: any) => (
                        <PlatformComponent key={platform} name={platform}/>
                    ))
                    }
                </div>
            </div>
        </div>
    );
}

