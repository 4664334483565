import React, {useState} from "react";
import PlayedGames from "./played/played-games";
import Wishlist from "./wishlist/wishlist";
import {PencilLine} from "phosphor-react";

export default Profile;

function Profile() {

    const [selectedTab, changeTab] = useState(1);

    return (
        <div className={"w-screen lg:px-[200px] md:px-5 sm:px-5"}>
            <div className="hero h-auto py-2 bg-base-200">
                <div className="hero-content flex-col w-full lg:justify-start lg:flex-row">
                    <div className="avatar">
                        <div className="w-24 rounded-full">
                            <img src="https://placeimg.com/192/192/people" alt={"Avatar"}/>
                        </div>
                    </div>
                    <div className={"ml-8"}>
                        <h1 className="text-xl font-bold pt-8">Harshdip Singh</h1>
                        <p className="text-sm pb-8"><i>You're a GamesBoxD user since {new Date().getFullYear()}!</i></p>
                        <button className="btn btn-primary btn-xs md:btn-sm lg:btn-sm gap-2">
                            <PencilLine size={20} weight="fill"/>
                            Edit profile
                        </button>
                    </div>
                </div>
            </div>
            <div className="tabs tabs-boxed">
                <p onClick={() => changeTab(1)}
                   className={selectedTab === 1 ? "tab tab-active" : "tab "}>
                    My games</p>
                <p onClick={() => changeTab(2)}
                   className={selectedTab === 2 ? "tab tab-active" : "tab"}>
                    Wishlist</p>
            </div>
            {selectedTab === 1 ?
                <PlayedGames/>
                : <Wishlist/>
            }
        </div>
    );
}
