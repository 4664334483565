import React, {useEffect, useState} from "react";
import axios from "axios";
import {Game} from "../../utilities/models/game";
import GameComponent from "../../components/game/gameComponent";

export default PlayedGames;

function PlayedGames() {

    let i_game: Game[] = [];
    const [games, setData] = useState(i_game);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        let items: Game[] = [];
        const getData = async () => {
            items = [];
            const {data} = await axios.get(`https://ou2u1foe44.execute-api.us-east-2.amazonaws.com/games`);
            data.forEach((game: any) => {
                items.push({
                    id: game.id,
                    name: game.name,
                    cover: game.cover,
                    genres: game.genres[0].split("#"),
                    platforms: game.platforms[0].split("#"),
                    rating: parseInt(game.rating).toFixed(2),
                    rate_count: game.rating_count
                });
            });
            setData(items);
        };

        getData().then(() => setLoading(false));
    }, []);

    return (
        <div>
            {loading ?
                <p>loading</p>
                :
                <div className={"flex flex-wrap"}>
                    {games.map((game: any) =>
                        <GameComponent game={game} key={game.id}/>
                    )}
                </div>
            }
        </div>
    );
}
