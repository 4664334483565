import React from "react";

export default Wishlist;

function Wishlist() {
    return (
        <div>
            <p>Hello World!</p>
        </div>
    );
}
