import React, {Component} from "react";
import {Link} from "react-router-dom";

class Home extends Component<any, any> {
    render() {
        return (
            <div className="hero h-auto py-10 bg-base-200">
                <div className="hero-content text-center">
                    <div className="max-w-md">
                        <h1 className="text-5xl font-bold">GamesBoxD</h1>
                        <div className="py-6">
                            <p className="py-1">Track games you’ve played.</p>
                            <p className="py-1">Save those you want to play.</p>
                            <p className="py-1">Tell your friends what’s good.</p>
                        </div>
                        <Link to="/register"><button className="btn btn-primary">Get Started</button></Link>
                        <Link to={"/profile"}><button className="btn btn-primary">Profile page here</button></Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
