import React from "react";

export default PlatformComponent;

function PlatformComponent(props: any) {
    return (
        <div className={"bg-sky-500 text-center text-sm py-1 px-2 m-1 rounded-full"}>
            <p>{props.name}</p>
        </div>
    );
}
